(function (factory) {
    typeof define === 'function' && define.amd ? define(['kendo.core'], factory) :
    factory();
})((function () {
    (function( window, undefined$1 ) {
        kendo.cultures["zh-TW"] = {
            name: "zh-TW",
            numberFormat: {
                pattern: ["-n"],
                decimals: 2,
                ",": ",",
                ".": ".",
                groupSize: [3],
                percent: {
                    pattern: ["-n%","n%"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "%"
                },
                currency: {
                    name: "New Taiwan Dollar",
                    abbr: "TWD",
                    pattern: ["-$n","$n"],
                    decimals: 2,
                    ",": ",",
                    ".": ".",
                    groupSize: [3],
                    symbol: "NT$"
                }
            },
            calendars: {
                standard: {
                    days: {
                        names: ["星期日","星期一","星期二","星期三","星期四","星期五","星期六"],
                        namesAbbr: ["週日","週一","週二","週三","週四","週五","週六"],
                        namesShort: ["日","一","二","三","四","五","六"]
                    },
                    months: {
                        names: ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"],
                        namesAbbr: ["一月","二月","三月","四月","五月","六月","七月","八月","九月","十月","十一月","十二月"]
                    },
                    AM: ["上午","上午","上午"],
                    PM: ["下午","下午","下午"],
                    patterns: {
                        d: "yyyy/M/d",
                        D: "yyyy'年'M'月'd'日'",
                        F: "yyyy'年'M'月'd'日' tt hh:mm:ss",
                        g: "yyyy/M/d tt hh:mm",
                        G: "yyyy/M/d tt hh:mm:ss",
                        m: "M月d日",
                        M: "M月d日",
                        s: "yyyy'-'MM'-'dd'T'HH':'mm':'ss",
                        t: "tt hh:mm",
                        T: "tt hh:mm:ss",
                        u: "yyyy'-'MM'-'dd HH':'mm':'ss'Z'",
                        y: "yyyy'年'M'月'",
                        Y: "yyyy'年'M'月'"
                    },
                    "/": "/",
                    ":": ":",
                    firstDay: 0
                }
            }
        };
    })();

}));
